const Loader = () => {
  return (
    <div className="loader">
      <div>
        <h1>Wait a little bit</h1>
        <h2>I&apos;m thinking</h2>
      </div>
    </div>
  );
};
export default Loader;
