import * as React from "react";
const SvgLightBulb = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F5DF5D"
}, d: "M127.511,312.576c0-91.76,71.9-116.231,71.9-166.806h113.186c0,50.574,71.9,75.046,71.9,166.806 c0,70.965-57.528,128.493-128.493,128.493S127.511,383.541,127.511,312.576z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M298.469,363.276c-2.809,0-5.541-1.458-7.046-4.065l-7.114-12.321l-7.114,12.321 c-1.452,2.514-4.135,4.064-7.039,4.064l0,0c-2.904,0-5.586-1.55-7.038-4.064l-7.113-12.321l-7.113,12.321 c-1.452,2.514-4.134,4.064-7.038,4.064c-2.903,0-5.586-1.55-7.038-4.064l-7.114-12.321l-7.113,12.321 c-2.245,3.887-7.216,5.218-11.102,2.975c-3.887-2.244-5.219-7.215-2.975-11.102l14.153-24.513c1.452-2.514,4.134-4.064,7.038-4.064 c2.903,0,5.586,1.55,7.038,4.064l7.114,12.321l7.113-12.321c1.452-2.514,4.134-4.064,7.038-4.064c2.904,0,5.586,1.55,7.038,4.064 l7.113,12.321l7.114-12.321c1.452-2.514,4.135-4.064,7.038-4.064l0,0c2.904,0,5.586,1.55,7.039,4.064l14.153,24.513 c2.244,3.887,0.912,8.858-2.975,11.102C301.246,362.925,299.848,363.276,298.469,363.276z" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.15
} }, /* @__PURE__ */ React.createElement("path", { d: "M194.503,333.687c-4.489,0-8.128-3.639-8.128-8.128c0-37.02,10.291-63.383,20.242-88.879 c10.025-25.686,20.393-52.246,20.393-90.91c0-4.489,3.639-8.128,8.128-8.128s8.128,3.639,8.128,8.128 c0,41.722-10.932,69.733-21.506,96.819c-9.838,25.203-19.129,49.009-19.129,82.97C202.631,330.048,198.992,333.687,194.503,333.687 z" }), /* @__PURE__ */ React.createElement("path", { d: "M317.497,333.687c-4.489,0-8.128-3.639-8.128-8.128c0-33.959-9.291-57.766-19.129-82.97 c-10.572-27.088-21.506-55.098-21.506-96.819c0-4.489,3.639-8.128,8.128-8.128c4.489,0,8.128,3.639,8.128,8.128 c0,38.662,10.366,65.222,20.393,90.91c9.951,25.496,20.242,51.86,20.242,88.879C325.624,330.048,321.985,333.687,317.497,333.687z" })), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  enableBackground: "new"
}, d: "M142.95,305.687c0-70.099,51.729-96.305,66.076-142.612h106.458 c-1.836-5.499-2.888-11.211-2.888-17.303H199.411c0,50.574-71.9,75.046-71.9,166.806c0,70.965,57.528,128.493,128.493,128.493 c20.592,0,40.047-4.852,57.3-13.463c-12.732,4.222-27.715,6.574-41.86,6.574C200.479,434.18,142.95,376.652,142.95,305.687z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.3,
  fill: "#FFFFFF",
  enableBackground: "new"
}, d: "M360.137,230.254c-0.358-0.005-0.711-0.027-1.071-0.027 c-36.396,0-65.902,29.505-65.902,65.902s29.505,65.902,65.902,65.902c5.663,0,11.159-0.716,16.403-2.059 c5.824-14.665,9.027-30.656,9.027-47.396C384.497,276.717,373.517,251.134,360.137,230.254z" }), /* @__PURE__ */ React.createElement("circle", { style: {
  opacity: 0.3,
  fill: "#FFFFFF",
  enableBackground: "new"
}, cx: 300.862, cy: 385.606, r: 18.805 })), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#7E858B"
}, cx: 255.998, cy: 23.884, r: 23.884 }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  fill: "#FFFFFF",
  enableBackground: "new"
}, d: "M266.024,2.205c-2.77,2.968-4.477,6.941-4.477,11.32 c0,9.177,7.439,16.616,16.616,16.616c0.305,0,0.601-0.029,0.903-0.046c0.532-1.981,0.82-4.062,0.82-6.211 C279.886,14.275,274.207,5.995,266.024,2.205z" }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#394049"
}, cx: 255.998, cy: 79.206, r: 57.511 }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  fill: "#FFFFFF",
  enableBackground: "new"
}, d: "M275.318,25.025c-4.985,6.64-7.939,14.89-7.939,23.831 c0,21.945,17.79,39.735,39.735,39.735c1.935,0,3.835-0.145,5.697-0.413c0.458-2.926,0.701-5.922,0.701-8.977 C313.512,54.218,297.576,32.963,275.318,25.025z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#7E858B"
}, d: "M320.919,79.202H191.09c-6.889,0-12.473,5.584-12.473,12.473v41.622 c0,6.889,5.584,12.473,12.473,12.473h129.829c6.889,0,12.473-5.584,12.473-12.473V91.675 C333.392,84.786,327.808,79.202,320.919,79.202z" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  enableBackground: "new"
}, d: "M327.885,138.804H198.056c-6.889,0-12.473-5.584-12.473-12.473V84.709 c0-1.632,0.323-3.186,0.892-4.614c-4.602,1.836-7.859,6.324-7.859,11.581v41.622c0,6.889,5.584,12.473,12.473,12.473h129.829 c5.257,0,9.746-3.256,11.581-7.859C331.071,138.481,329.517,138.804,327.885,138.804z" }), /* @__PURE__ */ React.createElement("rect", { x: 178.612, y: 104.358, style: {
  opacity: 0.2,
  enableBackground: "new"
}, width: 154.771, height: 16.255 }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  fill: "#FFFFFF",
  enableBackground: "new"
}, d: "M320.919,79.202h-32.573 c-0.273,1.743-0.418,3.527-0.418,5.347c0,18.913,15.333,34.248,34.248,34.248c3.931,0,7.702-0.671,11.218-1.89V91.675 C333.392,84.786,327.808,79.202,320.919,79.202z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E45D4C"
}, d: "M103.35,318.021H62.021c-4.489,0-8.128-3.639-8.128-8.128c0-4.489,3.639-8.128,8.128-8.128h41.329 c4.489,0,8.128,3.639,8.128,8.128C111.477,314.382,107.839,318.021,103.35,318.021z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E45D4C"
}, d: "M118.836,455.184c-2.08,0-4.16-0.793-5.747-2.381c-3.174-3.174-3.174-8.321,0-11.493l29.224-29.224 c3.174-3.174,8.32-3.174,11.494,0c3.174,3.174,3.174,8.32,0,11.493l-29.224,29.224 C122.996,454.391,120.915,455.184,118.836,455.184z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E45D4C"
}, d: "M256,512c-4.489,0-8.128-3.639-8.128-8.128v-41.329c0-4.489,3.639-8.128,8.128-8.128 c4.489,0,8.128,3.639,8.128,8.128v41.329C264.128,508.361,260.489,512,256,512z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E45D4C"
}, d: "M393.164,455.184c-2.08,0-4.16-0.793-5.747-2.381l-29.224-29.224c-3.174-3.174-3.174-8.32,0-11.493 c3.174-3.174,8.321-3.174,11.493,0l29.224,29.224c3.174,3.174,3.174,8.32,0,11.493 C397.324,454.391,395.244,455.184,393.164,455.184z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E45D4C"
}, d: "M449.979,318.021h-41.329c-4.489,0-8.128-3.639-8.128-8.128c0-4.489,3.639-8.128,8.128-8.128h41.329 c4.489,0,8.128,3.639,8.128,8.128C458.106,314.382,454.467,318.021,449.979,318.021z" })));
export default SvgLightBulb;
